import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  TextField,
  Button,
  Modal,
  Typography,
  Autocomplete,
  Box,
  IconButton,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import APIFunctions from "../../ApiFunction";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { countries } from "./countries";
import { Close } from "@mui/icons-material";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().when("isEditMode", {
    is: false,
    then: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  }),
  contact: Yup.string()
    .required("Contact is required")
    .matches(/^\d+$/, "Contact must be a number")
    .min(10, "Contact must be at least 10 digits")
    .max(15, "Contact can be at most 15 digits"),
  country_code: Yup.string().required("Country code is required"),
  _id: Yup.string().notRequired(),
});

const AddUserForm = ({
  isEditMode,
  openAddModal,
  getAll,
  setOpenAddModal,
  editData,
  setIsEditMode,
  setIsEditData,
}) => {
  const [countryCode, setCountryCode] = useState(editData?.country_code || "");
  // const [data, setData] = useState(editData);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      contact: "",
      country_code: "+1",
      _id: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        let payload = {
          name: values.name,
          email: values.email,
          password: values.password,
          contact: values.contact,
          country_code: values.country_code,
        };

        if (isEditMode) {
          payload._id = values._id;
        }

        const response = await APIFunctions.addEditUser(payload);

        if (response?.status === 200) {
          toast.success(response.data.message);
          setOpenAddModal(false);
          getAll();
          setIsEditData(null);
          resetForm();
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred");
      }
    },
    validationSchema,
  });

  useEffect(() => {
    if (editData != null) {
      formik.setFieldValue("name", editData?.name);
      formik.setFieldValue("email", editData?.email);
      formik.setFieldValue("contact", editData?.contact);
      formik.setFieldValue("_id", editData?._id);
      formik.setFieldValue("country_code", editData?.country_code);
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("contact", "");
      formik.setFieldValue("_id");
      formik.setFieldValue("country_code", "+1");
    }
  }, [editData, setIsEditData]);

  return (
    <Modal
      open={openAddModal}
      onClose={() => {
        setIsEditData(null);
        setOpenAddModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="root">
        <Box position="absolute" top={0} right={0}>
          <IconButton style={{ color: "white" }}>
            <Close />
          </IconButton>
        </Box>
        <Paper className="paper">
        <DialogTitle>{isEditMode ? "EDIT" : "ADD"} USER</DialogTitle>
        

          <form className="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  className="textField"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className="textField"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled={isEditMode}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className="textField"
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
             
              <Grid item xs={3}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.phone}
                  onChange={(e, value) => {
                    formik.setFieldValue("country_code", value.phone);
                    setCountryCode(value.phone);
                  }}
                  value={countries.find(
                    (country) => country.phone === formik.values.country_code
                  )}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box
                        key={key}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...optionProps}
                      >
                        <img
                          loading="lazy"
                          width="15"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label} ({option.code}) {option.phone}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        type: "search",
                      }}
                      helperText={formik.errors.country_code}
                      error={Boolean(formik.errors.country_code)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="contact"
                  label="Contact"
                  name="contact"
                  autoComplete="contact"
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  className="textField"
                  error={
                    formik.touched.contact && Boolean(formik.errors.contact)
                  }
                  helperText={formik.touched.contact && formik.errors.contact}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="submit"
                >
                  {isEditMode ? "Edit" : "Add"} User
                </Button> */}
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpenAddModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </Modal>
  );
};

export default AddUserForm;
