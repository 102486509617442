import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import { useAsyncError, useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";
import APIFunctions from "../../ApiFunction";



const BackPaper = styled(Paper)({
    height: "80vh",
    overflow: "auto",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Terms = () => {
    const [state, setState] = useState('');
    const [id,setId] = useState('')
    const navigate = useNavigate();
    const [type,setType] = useState(1)
    const [disable,setDisable] = useState(false)

    const handleChange = data => {
        setState(data)
    };
    const updateContent = async () => {
        let data = {
            content:state,
            type:type
        }
        try {
            setDisable(true)
            let result = await APIFunctions.updateContentByType(data)
            if (result.data.code === 200) {
                toast.success("Successfully updated!")
                setDisable(false)
            } else if (result.data.code === 203) {
                toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();
            }
        } catch (err) {
            console.log(err.message)
        }
    }
    const getAll = async () => {
        try {
            let result = await APIFunctions.getContentByType(type)
            if (result.data.code === 200) {
                setState(result.data.result.content)
            } else if (result.data.code === 203) {
                toast.info("Session Expired. Please login again!!");
        localStorage.clear();
        navigate("/")
        window.location.reload();
            }
        } catch (err) {
            console.log(err.message)
        }
    }
    useEffect(() => {
        getAll()
    }, [])


    return (
        <div className='container-fluid'>
            <Toptag />
            {/* <ToastContainer  autoClose={1000}/> */}
            <BackPaper>
                <EditorToolbar />
                <ReactQuill
                    style={{ height: '65vh', overflow: "auto" }}
                    theme="snow"
                    id='quillState'
                    value={state}
                    onChange={handleChange}
                    placeholder={"Write something..."}
                    modules={modules}
                    formats={formats}
                />
                <Grid container style={{ marginTop: "10px" }} display="flex" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                        <button className="newBtn" onClick={updateContent} disabled={disable}>SUBMIT</button>
                    </Grid>
                </Grid>
            </BackPaper>
        </div>
    )
}

export default Terms