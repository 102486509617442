import React, { useEffect, useState } from 'react';
import './changePass.css'
import axios from 'axios';
import './profile.css';
import Toptag from '../../components/topTag/Toptag';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../components/loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { CancelOutlined, CheckCircleOutlineOutlined, InsertEmoticonOutlined, MoodBadOutlined, RemoveRedEye, VisibilityOff } from '@mui/icons-material/';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import APIFunctions from '../../ApiFunction';




const BackPaper = styled(Paper)({
    padding: "20px",
    height: "80vh",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
export default function ChangePass() {
    const [password, setPassword] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    });
    const navigate = useNavigate();
    const [err, setError] = useState(false);
    const [disable, setDisable] = useState(false);



    const inputEvent = (e) => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        });
    };

    const submitVal = async () => {
        try {
            if (password.old_password === '' || password.new_password === '' || password.confirm_password === '') {
                setError(true)
            } else if (password.new_password !== password.confirm_password) {
                setError(true)
            } else if (password.new_password.length < 8) {
                setError(true)
            } else {
                let data = {
                    password: password.new_password,
                    old_password: password.old_password
                }
                setDisable(true)
                setError(false)
                const result = await APIFunctions.changeAdminPassword(data);
                if (result.data.code === 200) {
                    toast.success('Password changed successfully!');
                    setError(false)
                    setPassword({
                        old_password: "",
                        new_password: "",
                        confirm_password: ""
                    });
                    setDisable(false)
                    setError(false)
                } else if (result.data.code === 201) {
                    toast.warning('Incorrect old password!');
                    setDisable(false)
                } else if (result.data.code === 203) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate("/")
                    toast.info("Session Expired Please login again!!");
                    window.location.reload(true);
                }else{
                    toast.info("Something went wrong!!");

                }
            }
        } catch (error) {
            console.log(error.message)
        }
    };




    return (
        <>
            <Toptag />
          
            <BackPaper>
                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item><label className='labelCont'>Old Password</label></Grid>
                            <Grid item>
                                <input type="password" value={password.old_password} name="old_password" id="old_password" className="searchBarInput" onChange={inputEvent} />
                            </Grid>
                            <Grid item>
                                {err && password.old_password === '' ? <div className='errText'>Old password required!</div> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item><label className='labelCont'>New Password</label></Grid>
                            <Grid item>
                                <input type="password"
                                    name="new_password" value={password.new_password} id="new_password" className="searchBarInput" onChange={inputEvent} />
                            </Grid>
                            <Grid item>
                                {err && password.new_password === '' ? <div className='errText'>New password required!</div> : null}
                                {err && password.new_password !== '' && password.new_password.length < 8 ? <div className='errText'>New password must be greater then 8 character!</div> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item><label className='labelCont'>Confirm New Password</label></Grid>
                            <Grid item>
                                <input type="password"
                                    value={password.confirm_password}
                                    name="confirm_password" id="confirm_password" className="searchBarInput" onChange={inputEvent} />
                            </Grid>
                            <Grid item>
                                {err && password.confirm_password === '' ? <div className='errText'>Confirm new password required!</div> : null}
                                {err && password.confirm_password !== '' && password.new_password !== password.confirm_password ? <div className='errText'>Confirm password must be same as new password!</div> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={3} style={{marginTop:"30px"}}>
                    <Grid xs={12} sm={12} md={6} lg={6} item>
                    <Grid container spacing={6} direction="column" justifyContent="space-evenly"  alignItems="center">
                        <Grid item><label className='labelCont'>Old Password</label></Grid>
                        <Grid item><label className='labelCont'>New Password</label></Grid>
                        <Grid item><label className='labelCont'>New Password</label></Grid>
                    </Grid>
                    </Grid>
                    
                    <Grid xs={12} sm={12} md={6} lg={6} item>
                    <Grid container spacing={3} direction="column" justifyContent="space-evenly" alignItems="center">
                        <Grid style={{width:"100%"}} item>
                        <input type="password" name="oldPassword" value={one.oldPassword} id="oldPassword" className="searchBarInput" onChange={inputEvent}/>
                        </Grid>
                        <Grid style={{width:"100%"}} item>
                        <input type="password" name="newPassword" value={one.newPassword} id="newPassword" className="searchBarInput" onChange={inputEvent}/>
                        </Grid>
                        <Grid style={{width:"100%"}} item>
                        <input type="password" name="retypePassword" value={one.retypePassword} id="retypePassword" className="searchBarInput" onChange={inputEvent}/>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid> */}

                <Grid container direction="row" spacing={1} style={{ marginTop: "50px" }} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Tooltip title="Click to Update Password" >
                            <button className='newBtn' onClick={submitVal} disabled={disable}>Change Password</button>
                        </Tooltip>
                    </Grid>
                </Grid>





            </BackPaper></>

    )
}
