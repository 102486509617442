import {
  Block,
  Call,
  Cancel,
  Close,
  Delete,
  DeleteOutline,
  DoneAll,
  Edit,
  FreeBreakfastSharp,
  GppMaybeOutlined,
  MarkEmailRead,
  MarkEmailUnread,
  PhoneDisabled,
  PhoneLocked,
  RemoveCircleOutline,
  RemoveRedEye,
  Search,
  Unsubscribe,
  VerifiedOutlined,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/loader/Loader";
import Toptag from "../../components/topTag/Toptag";
import "./user.css";
import APIFunction from "../../ApiFunction";
import { elements } from "chart.js";
import { fetchUsersCount } from "../../action/count";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, Img_url } from "../../Api";
import APIFunctions from "../../ApiFunction";
import { useFormik } from "formik";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import AddUserForm from "./AddUser";

const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const BackPaper = styled(Paper)({
  height: "70vh",
  overflow: "auto",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});
const User = () => {
  const dispatch = useDispatch();
  const [usersCount, setUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [row, setRow] = useState(10);
  const [skip, setSkip] = useState(0);
  const [key, setKey] = useState("");
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [contact, setContact] = React.useState(false);
  const [id, setId] = useState();
  const [status, setStatus] = useState();
  const [disable, setDisable] = useState(false);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editData, setIsEditData] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [userDeleteId, setuserDeleteId] = React.useState(null);



  useEffect(() => {
    dispatch(fetchUsersCount());
  }, [dispatch]);

  const handleBlockOpen = (id, status) => {
    setId(id);
    setStatus(status);
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleEmailOpen = (id) => {
    setId(id);
    setRemOpen(true);
  };

  const handleEmail = () => {
    setRemOpen(false);
  };

  const navigateToAddUser = (id) => {
    setIsEditMode(false);
    setIsEditData(null);
    setOpenAddModal(true);
  };

  const handleContact = () => {
    setContact(false);
  };

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery);
    setSkip(0);
  };

  const getAll = async () => {
    const result = await APIFunction.getAllNormalUser(key, row, skip);
    if (result.data.code === 200) {
      setAll(result.data.result);
      setUserCount(result.data.total_count);
      console.log("result.data.total_count", result.data.total_count);
      setIsLoading(false);
    } else if (result.data.code === 203) {
      toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }
  };
  useEffect(() => {
    getAll();
  }, [key, row, skip]);

  const updateEmail = async (id) => {
    setDisable(true);
    const result = await APIFunction.updateEmailStatus(id);
    if (result.data.code === 200) {
      setRemOpen(false);
      getAll();
      setDisable(false);
      toast.success("Verify Successfully!");
    } else if (result.data.code === 201) {
      toast.error("Please try again!");
    } else if (result.data.code === 203) {
      window.location.reload();
      localStorage.clear();
      navigate("/");
      toast.info("Session Expired Please login again!!");
    }
  };

  const updateContact = async (id) => {
    setDisable(true);
    const result = await APIFunction.updateContactStatus(id);
    if (result.data.code === 200) {
      setContact(false);
      getAll();
      setDisable(false);
      toast.success("Verify Successfully!");
    } else if (result.data.code === 201) {
      toast.error("Please try again!");
    } else if (result.data.code === 203) {
      window.location.reload();
      localStorage.clear();
      navigate("/");
      toast.info("Session Expired Please login again!!");
    }
  };

  const BlockUser = async (id) => {
    let data = {
      blockedbyAdmin: status,
    };
    setDisable(true);
    const result = await APIFunction.updateBlockStatus(id, data);
    if (result.data.code === 200) {
      setOpen(false);
      getAll();
      setDisable(false);
      toast.success("Successfully updated!");
    } else if (result.data.code === 201) {
      toast.error("Please try again!");
    } else if (result.data.code === 203) {
      window.location.reload();
      localStorage.clear();
      navigate("/");
      toast.info("Session Expired Please login again!!");
    }
  };

  const handleChange = (e) => {
    if (e.target.value === usersCount) {
      setSkip(0);
      setRow(usersCount);
    } else {
      setRow(e.target.value);
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row);
  };
  const handleDeleteuser = async (_id) => {
    console.log("handleDeleteuser", _id);
    try {
      const deleteUser = await APIFunction.deleteUser(_id);
      if (deleteUser.status === 200) {
        getAll();
        toast.success("User deleted successfully");
        setShowDeleteModal(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <Toptag />
            <NavPaper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div></div>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <div
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "grey",
                      }}
                    >
                      Active Users {usersCount}
                    </div>
                  </Grid>
                  {all.length > 0 ? (
                    <>
                      <Grid item>
                        <div
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "grey",
                          }}
                        >
                          Row Per Page{" "}
                        </div>
                      </Grid>
                      <Grid item>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 50 }}
                        >
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard-label"
                            value={row}
                            onChange={handleChange}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={usersCount}>All</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item>
                    <div className="searchBar">
                      <input
                        type="text"
                        placeholder="Search... "
                        onChange={searchHandle}
                        id="searchtext"
                        className="searchBarInput"
                      />
                      <Search className="searchIcon" />
                    </div>
                  </Grid>
                  <Box
                    sx={{
                      margin: "10px",
                      paddingTop: "20px",
                      color: "white",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={navigateToAddUser}
                    >
                      Add
                    </Button>
                  </Box>
                </Grid>
              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 && key ? (
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "30px" }}
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {/* <Grid item>
                    <img
                      src="/images/no-data.png"
                      alt="error-image"
                      style={{ width: "50%", heigth: "500px" }}
                    />
                  </Grid> */}
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      marginTop: "100px",
                    }}
                  >
                    <div className="errorMessage">No Data Found!</div>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Login Type</TableCell>
                        <TableCell>Joined Since</TableCell>
                        <TableCell>Email Verified</TableCell>
                        <TableCell>Contact Verified</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {all.map((element, index) => {
                        let date = new Date(element.created_at);
                        let datearr = [
                          "Jan",
                          "Feb",
                          "March",
                          "Apr",
                          "May",
                          "Jun",
                          "July",
                          "Aug",
                          "Sept",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        let newDate =
                          date.toISOString().substring(0, 10).split("-")[2] +
                          " " +
                          datearr[
                            parseInt(
                              date.toISOString().substring(0, 10).split("-")[1]
                            ) - 1
                          ] +
                          " " +
                          date.toISOString().substring(0, 10).split("-")[0];
                        return (
                          <>
                            <TableRow key={index + 1}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {
                                  <img
                                    src={
                                      element.profile_pic &&
                                      element.profile_pic !== ""
                                        ? `${Img_url}${element.profile_pic}`
                                        : "/images/blank_pic.png"
                                    }
                                    alt="user"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50px",
                                    }}
                                  />
                                }
                              </TableCell>
                              <TableCell>{element.name}</TableCell>
                              <TableCell>{element.email}</TableCell>
                              <TableCell>
                                {element.login_type === "EMAIL" ? (
                                  <img
                                    className="logintype"
                                    src="/images/email.png"
                                    alt="Email"
                                  />
                                ) : element.login_type === "GOOGLE" ? (
                                  <img
                                    className="logintype"
                                    src="/images/google.png"
                                    alt="Google"
                                  />
                                ) : (
                                  <img
                                    className="logintype"
                                    src="/images/phone.png"
                                    alt="Phone"
                                  />
                                )}
                              </TableCell>
                              <TableCell>{newDate}</TableCell>
                              <TableCell>
                                {element.emailVerified === true ? (
                                  <div className="purchaseChip">
                                    <VerifiedOutlined
                                      style={{ color: "green" }}
                                    />
                                    <div>Verified</div>
                                  </div>
                                ) : (
                                  <div className="purchaseChip">
                                    <GppMaybeOutlined
                                      style={{ color: "red" }}
                                    />
                                    <div>Not Verified</div>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                {element.contactVerified === true ? (
                                  <div className="purchaseChip">
                                    <VerifiedOutlined
                                      style={{ color: "green" }}
                                    />
                                    <div>Verified</div>
                                  </div>
                                ) : (
                                  <div className="purchaseChip">
                                    <GppMaybeOutlined
                                      style={{ color: "red" }}
                                    />
                                    <div>Not Verified</div>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Grid
                                  container
                                  spacing={1}
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Tooltip title="view profile">
                                      <NavLink to="/users/details">
                                        <RemoveRedEye
                                          onClick={() =>
                                            localStorage.setItem(
                                              "user_id",
                                              element._id
                                            )
                                          }
                                          className="visibilityIc"
                                        />
                                      </NavLink>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title="edit profile">
                                      <Edit
                                        onClick={() => {
                                          setIsEditData({
                                            name: element.name,
                                            email: element.email,
                                            password: element.password,
                                            contact: element.contact,
                                            _id: element._id,
                                            country_code: element.country_code,
                                          });
                                          setIsEditMode(true);
                                          setOpenAddModal(true);
                                        }}
                                        className="visibilityIc"
                                      />
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title="delete user">
                                      <Delete
                                        onClick={() => {
                                          setShowDeleteModal(true)
                                          setuserDeleteId(element._id)
                                          // handleDeleteuser(element._id);
                                        }}
                                        className="visibilityIc"
                                      />
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    {
                                      element.blockedbyAdmin === true ? (
                                        // <Tooltip title={`unblock`}>
                                        <Block
                                          style={{ color: "red" }}
                                          className="blockIcon"
                                          onClick={() => {
                                            handleBlockOpen(
                                              element._id,
                                              element.blockedbyAdmin
                                            );
                                          }}
                                        />
                                      ) : (
                                        // </Tooltip>
                                        // <Tooltip title={`block`}>
                                        <Block
                                          style={{ color: "grey" }}
                                          className="blockIcon"
                                          onClick={() => {
                                            handleBlockOpen(
                                              element._id,
                                              element.blockedbyAdmin
                                            );
                                          }}
                                        />
                                      )
                                      // </Tooltip>
                                    }
                                  </Grid>
                                  <Grid item>
                                    {element.emailVerified === true ? (
                                      // <Tooltip title="Email already verified" >
                                      <MarkEmailRead
                                        className="userListDelete"
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      // </Tooltip>
                                      <Tooltip
                                        title={
                                          element.email === ""
                                            ? "User hasn't added any email yet"
                                            : "Verify Now"
                                        }
                                      >
                                        {element.email === "" ? (
                                          <Unsubscribe className="userListDelete" />
                                        ) : (
                                          <MarkEmailUnread
                                            className="userListDelete"
                                            onClick={() => {
                                              handleEmailOpen(element._id);
                                            }}
                                          />
                                        )}
                                      </Tooltip>
                                    )}
                                  </Grid>
                                  {/* <Grid item>
                                  {element.contactVerified === true  ?
                                    <Tooltip title="Contact already verified" >
                                      <Call className="userListDelete" style={{ color: "green" }} />
                                    </Tooltip>
                                    :
                                    <Tooltip title={element.contact === ""?"User hasn't added any contact yet":"Verify Now"}>
                                      {element.contact === ""?
                                      <PhoneDisabled className="userListDelete"/>
                                      :
                                      <PhoneLocked className="userListDelete" onClick={() => { handleContactOpen(element._id) }} />
                                       }
                                      </Tooltip>
                                  }
                                </Grid> */}
                                  <Grid item></Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
              {/* dilogue for block user */}
              <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {status === true
                    ? "Are you sure want to unblock this user?"
                    : "Are you sure want to block this user?"}
                </DialogTitle>
                <DialogActions>
                  <Button variant="outlined" onClick={handleBlock}>
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      BlockUser(id);
                    }}
                    disabled={disable}
                  >
                    {status === true ? "unBlock" : "Block"}
                  </Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for verify user */}
              <Dialog
                open={remopen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to verify this user by your side?"}
                </DialogTitle>
                <DialogActions>
                  <Button variant="outlined" onClick={handleEmail}>
                    No
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      updateEmail(id);
                    }}
                    disabled={disable}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for verify user */}
              <Dialog
                open={contact}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to verify the contact?"}
                </DialogTitle>
                <DialogActions>
                  <Button variant="outlined" onClick={handleContact}>
                    No
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      updateContact(id);
                    }}
                    disabled={disable}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </BackPaper>
            {all.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div>
                  <Pagination
                    count={Math.ceil(usersCount / row)}
                    page={Math.floor(skip / row) + 1}
                    variant="outlined"
                    onChange={skipPagination}
                    shape="rounded"
                  />
                </div>
              </div>
            ) : null}
          </Container>
        </>
      )}
      <AddUserForm
        isEditMode={isEditMode}
        openAddModal={openAddModal}
        getAll={getAll}
        setOpenAddModal={setOpenAddModal}
        setIsEditMode={setIsEditMode}
        editData={editData}
        setIsEditData={setIsEditData}
      />

      <Dialog open={showDeleteModal} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm the action</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={()=> setShowDeleteModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>Are you sure you want to delete the user?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={()=>setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={()=>{
            handleDeleteuser(userDeleteId)
          }} >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default User;
