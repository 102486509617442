import React, { useEffect, useState } from "react";
import "./topbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Backdrop, Button, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Person, Key, Logout } from "@mui/icons-material";
import APIFunctions from "../../ApiFunction";
import { Img_url } from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { setAdmin } from "../../slice/adminslice";

export default function Topbar({onLogout}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [pic,setPic] = useState()
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.admin);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutClick = async (e) => {
    try {
      let result = await APIFunctions.logout();
      if (result.data.code === 200) {
        localStorage.clear();
        sessionStorage.clear();
        toast.success("Logout Successfully!");
        navigate("/");
        onLogout();
      }
    } catch (err) {
      console.log(err.message);
    }
  };

useEffect(()=>{
  dispatch(setAdmin(localStorage.getItem('Admin_image') ?? ""))
},[])

  return (
    <>
      <div className="topbarWrapper">
        <div className="logoContainer">
        <NavLink to='/home' className='link'>
          <img
            src="/images/Spend app icon.jpg"
            alt="logo"
            className="logoImg"
          ></img>

          </NavLink>
          <span className="spanName">$pend App</span>
        </div>
        <div className="topRight">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ shadow: "none" }}
          >
            <img src={userDetails.Admin_image !== "" ? `${Img_url}${userDetails.Admin_image}` : '/images/blank_pic.png'} alt="" className="topAvatar" />
         
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <NavLink to="/profile" className="link">
              <div>
                <MenuItem onClick={handleClose}>
                  <Person className="menuIcons" />
                  Profile
                </MenuItem>
              </div>
            </NavLink>
            <NavLink to="/change-password" className="link">
              <div>
                <MenuItem onClick={handleClose}>
                  <Key className="menuIcons" />
                  Change Password
                </MenuItem>
              </div>
            </NavLink>
            <MenuItem onClick={logoutClick}>
              <Logout className="menuIcons" />
              Logout
            </MenuItem>
            <ToastContainer />
          </Menu>
        </div>
      </div>
    </>
  );
}
