import "./card.css";
import {
  ArrowUpward,
  DirectionsCar,
  Drafts,
  Group,
  MovieFilter,
  Receipt,
  TipsAndUpdates,
  Wallet,
} from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import Toptag from "../../components/topTag/Toptag";
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import APIFunction from "../../ApiFunction";
import Loader from "../loader/Loader";
import { fetchUsersCount } from "../../action/count";
import { useDispatch, useSelector } from "react-redux";

export default function Card() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const usersCount = useSelector((state) => state.count.usersCount);
  const wallet = useSelector((state) => state.count.wallet);
  const transaction = useSelector((state) => state.count.transaction);
  const envelopes = useSelector((state) => state.count.envelopes);

  const isLoading = useSelector((state) => state.count.isLoading);
  const error = useSelector((state) => state.count.error);

  if (error === 203) {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    toast.info("Session Expired Please login again!!");
    window.location.reload(true);
  }

  useEffect(() => {
    dispatch(fetchUsersCount());
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Toptag />
          <div className="container-fluid">
            <div className="cardWrapper">
              <NavLink to="/users" className="link">
                <div className="cardContainer">
                  <div className="cardTopLogo">
                    <Group className="cardLogo" sx={{ color: "#a07c7c" }} />
                  </div>
                  <span className="cardTitle">Users</span>
                  <span className="userCount">{usersCount}</span>
                </div>
              </NavLink>
              <NavLink to="/wallets" className="link">
                <div className="cardContainer">
                  <div className="cardTopLogo">
                    <Wallet className="cardLogo" sx={{ color: "#6868a4" }} />
                  </div>
                  <span className="cardTitle">Wallets</span>
                  <span className="userCount">{wallet}</span>
                </div>
              </NavLink>
              <NavLink to="/transactions" className="link">
                <div className="cardContainer">
                  <div className="cardTopLogo">
                    <Receipt className="cardLogo" sx={{ color: "#ffffb1" }} />
                  </div>
                  <span className="cardTitle">Transactions</span>
                  <span className="userCount">{transaction}</span>
                </div>
              </NavLink>
              <NavLink to="/envelopes" className="link">
                <div className="cardContainer">
                  <div className="cardTopLogo">
                    <Drafts className="cardLogo" sx={{ color: "#35bbc9" }} />
                  </div>
                  <span className="cardTitle">Envelopes</span>
                  <span className="userCount">{envelopes}</span>
                </div>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </>
  );
}
