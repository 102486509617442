
// const BASE_URL = 'http://localhost:3010/admin/v1/'
// const Img_url = 'http://localhost:3010/uploads/'
const BASE_URL = 'https://api.spendyourway.net/admin/v1/'
const Img_url = 'https://api.spendyourway.net/uploads/'


const API = {
    LOGIN: BASE_URL + 'login',
    AUTH: BASE_URL + 'auth',
    LOGOUT: BASE_URL + 'logout',
    PUTOBJURL: BASE_URL + 'putObjectUrl',
    forgotPassword: BASE_URL + 'forgotPassword',
    UPDATE_ADMIN_PROFILE: BASE_URL + 'updateProfile',
    CHANGE_PASSWORD: BASE_URL + 'change_password',
    GET_ALL_TRIVIA_QUE: BASE_URL + 'get_trivia_que',
    GET_ALL_SERVICEGUIDE_PAGE: BASE_URL + 'get_service_guide',
    GET_ALL_VIDEOS: BASE_URL + 'get_videos',
    GET_ALL_VEHICLES: BASE_URL + 'get_vehicles',
    GET_ALL_TIPS: BASE_URL + 'get_tips',
    GET_SERVICEGUIDE_DETAIL: BASE_URL + 'get_service_guide_detail',
    GET_TIP_DETAIL: BASE_URL + 'get_tips_detail',

    DELETE_USER: BASE_URL + 'delete_user',
    DELETE_TRIVIA_QUE: BASE_URL + 'del_trivia_que',
    DELETE_SERVICEGUIDE: BASE_URL + 'delete_service_guide',
    DELETE_VIDEO: BASE_URL + 'delete_video',
    DELETE_TIP: BASE_URL + 'delete_tips',
    COUNT: BASE_URL + 'home_counts',

    ADD_VIDEOS: BASE_URL + 'add_videos',
    UPDATE_VIDEOS: BASE_URL + 'update_video',

    GET_ALL_NORMAL_USER: BASE_URL + 'get_users',
    GET_ALL_TECHNICIAN: BASE_URL + 'get_technicians',
    GET_USER_DETAILS: BASE_URL + 'get_users_details',
    GET_VEHICLE_DETAILS: BASE_URL + 'get_vehicle_details',
    get_vehicle_dropDownListing: BASE_URL + 'get_vehicle_dropDownListing',
    GET_VIDEO_DETAILS: BASE_URL + 'get_video_detail',
    GET_VIDEO_DETAIL_EDITSCREEN: BASE_URL + 'get_video_detail_forEdit',
    GET_TRIVIAQUE_DETAIL: BASE_URL + 'get_trivia_que_detail',
    GET_VIDEO_DETAIL: BASE_URL + 'get_video_detail',
    UPDATE_USER_DETAILS: BASE_URL + 'updateUser_Profile',
    ADD_SERVICE_GUIDE: BASE_URL + 'add_service_guide',
    UPDATE_SERVICE_GUIDE: BASE_URL + 'update_service_guide',
    UPDATE_TIP: BASE_URL + 'update_tips',
    ADD_TIPS: BASE_URL + 'add_tips',

    GET_ADMIN_PROFILE: BASE_URL + 'getAdminProfile',

    UPDATE_BLOCK_UNBLOCK_USER: BASE_URL + 'block_user',
    UPDATE_EMAIL_STATUS_USER: BASE_URL + 'emailverify_user',
    UPDATE_CONTACT_STATUS_USER: BASE_URL + 'contactverify_user',

    UNARCHIVED_USER: BASE_URL + 'unarchive_user',
    GET_ALL_DELETED_USERS: BASE_URL + 'get_users_deleted',

    GET_ALL_FLASHCARD: BASE_URL + 'get_flashcards',
    GET_FLASHCARD_DETAILS: BASE_URL + 'get_flashcards_details',


    GET_DEFAULT_TEXT: BASE_URL + 'get_text',
    UPDATE_DEFAULT_TEXT: BASE_URL + 'add_text',

    ADD_CONTACT_US_OPTIONS: BASE_URL + 'add_contactus_data',
    ADD_TRIVIA_QUE: BASE_URL + 'add_trivia_que',
    GET_ALL_CONTACT_OPTIONS: BASE_URL + 'get_contact_listing',
    EDIT_CONTACT_OPTIONS: BASE_URL + 'edit_contactus_data',
    GET_ALL_CONTACT_MESSAGES: BASE_URL + 'get_contactus_queries',
    GET_CONTACT_MESSAGE_DETAIL: BASE_URL + 'get_contactus_queries_detail',

    GET_CONTNET_BY_TYPE: BASE_URL + 'getContentPages',
    UPDATE_CONTNET_BY_TYPE: BASE_URL + 'add_contentPages',
    GET_WALLETS: BASE_URL + 'get_wallets',
    GET_WALLETS_TRANSACTIONS: BASE_URL + 'get_transactions',
    GET_ALL_TRANSACTIONS: BASE_URL + 'get_all_transactions',
    GET_ALL_ENVELOPES: BASE_URL + 'get_all_envelopes',
    GET_COLORS:BASE_URL + "get_colors",
    ADD_COLOR:BASE_URL + "add_color",
    DELETE_COLOR:BASE_URL + "delete_color",
    ADD_EDIT_USER:BASE_URL + "add_edit_user",
    DELETE_USER:BASE_URL + "delete_user",








}

export default API
export { BASE_URL, Img_url }

