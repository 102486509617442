import React, { useEffect, useState } from "react";
import APIFunctions from "../../ApiFunction";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import {
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableCell,
  TableHead,
  Select,
  TableRow,
  TableBody,
  Tooltip,
  Box,
  TableContainer,
  styled,
  Pagination,
} from "@mui/material";
import Toptag from "../../components/topTag/Toptag";
import { useLocation, useNavigate } from "react-router-dom";

import { RemoveRedEye, Search, SouthWest } from "@mui/icons-material";
import NorthEastIcon from "@mui/icons-material/NorthEast";

const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const BackPaper = styled(Paper)({
  height: "70vh",
  overflow: "auto",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Transactions() {
  const [all, setAll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [usersCount, setUserCount] = useState(0);
  const navigate = useNavigate();
  const [row, setRow] = useState(10);
  const [skip, setSkip] = useState(0);
  const location = useLocation();
  const [key, setKey] = useState(location.state || "");

  const getAll = async () => {
    const result = await APIFunctions.getAllTransactions(key, row, skip);
    if (result.data.code === 200) {
      setAll(result.data.result);
      setUserCount(result.data.total_count);
      setIsLoading(false);
    } else if (result.data.code === 203) {
      toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }
  };

  useEffect(() => {
    getAll();
  }, [row, key, skip]);

  function convertEpochToSpecificTimezone(timeEpoch, offset) {
    var d = new Date(timeEpoch);
    var utc = d.getTime() + d.getTimezoneOffset() * 60000; // This converts to UTC 00:00
    var nd = new Date(utc + 3600000 * offset);
    return nd.toLocaleString();
  }

  const handleChange = (e) => {
    const value = e.target.value;
    setRow(value);
    setSkip(0); // Reset to the first page whenever the rows per page change
  };

  const skipPagination = (e, page) => {
    setSkip((page - 1) * row);
  };

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery);
    setSkip(0); // Reset to the first page whenever a new search is initiated
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <Toptag />
          <NavPaper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div></div>
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {all?.data?.length > 0 ? (
                  <>
                    <Grid item>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Row Per Page{" "}
                      </div>
                    </Grid>
                    <Grid item>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard-label"
                          value={row}
                          onChange={handleChange}
                        > 
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={usersCount}>All</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : null}
                <Grid item>
                  <div className="searchBar">
                    <input
                      type="text"
                      placeholder="Search... "
                      onChange={searchHandle}
                      id="searchtext"
                      className="searchBarInput"
                    />
                    <Search className="searchIcon" />
                  </div>
                </Grid>
              </Grid>
            </div>
          </NavPaper>
          <BackPaper>
            {all?.data?.length === 0 ? (
              <Grid
                container
                spacing={2}
                style={{ marginTop: "30px" }}
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item>
                  {/* <img
                    src="/images/no-data.png"
                    alt="error-image"
                    style={{ width: "50%", heigth: "500px" }}
                  /> */}
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "100px",
                  }}
                >
                  <div className="errorMessage">No Data Found!</div>
                </Grid>
              </Grid>
            ) : (
              <>
                <Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Deposit/Widthdraw</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>User</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Wallet Id</TableCell>
                          <TableCell>Transaction Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {all?.data?.map((row) => (
                          <TableRow key={row._id}>
                            <TableCell component="th" scope="row">
                              {!row.in ? (
                                <>
                                  <NorthEastIcon style={{ color: "red" }} />{" "}
                                  <p style={{ color: "red" }}>Widthraw</p>
                                </>
                              ) : (
                                <>
                                  <SouthWest style={{ color: "green" }} />{" "}
                                  <p style={{ color: "green" }}>Deposit</p>
                                </>
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <span>
                                <p style={{ color: !row.in ? "red" : "green" }}>
                                  {row.in ? "+" : "-"} ${row.amount}
                                </p>
                              </span>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.userId?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.userId?.email}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.walletId ? row?.walletId?._id : "-"}
                            </TableCell>
                            <TableCell>
                              {convertEpochToSpecificTimezone(
                                row.created_at,
                                +3
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )}
          </BackPaper>
          {all?.data?.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div>
                <Pagination
                  count={Math.ceil(usersCount / row)}
                  page={Math.floor(skip / row) + 1}
                  variant="outlined"
                  onChange={skipPagination}
                  shape="rounded"
                />
              </div>
            </div>
          ) : null}
        </Container>
      )}
    </div>
  );
}
