import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import {
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableCell,
  TableHead,
  Select,
  TableRow,
  TableBody,
  Tooltip,
  Modal,
  Box,
  TableContainer,
  Pagination,
} from "@mui/material";
import Toptag from "../../components/topTag/Toptag";
import { RemoveRedEye, Search, SouthWest } from "@mui/icons-material";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { styled } from "@mui/system";
import APIFunctions from "../../ApiFunction";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_URL, Img_url } from "../../Api";

const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const BackPaper = styled(Paper)({
  height: "70vh",
  overflow: "auto",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 600,

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Wallets() {
  const [isLoading, setIsLoading] = useState(true);
  const [openTransactionsModal, setOpenTransactionsModal] = useState(false);
  const [openEnvelop, setOpenEnvelop] = useState(false);
  const [all, setAll] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [allEnvelopes, setAllEnvelepes] = useState([]);
  const [usersCount, setUserCount] = useState(0);
  const navigate = useNavigate();
  const [row, setRow] = useState(10);
  const [skip, setSkip] = useState(0);
  const [key, setKey] = useState("");

  const getAll = async () => {
    const result = await APIFunctions.getWallets(key, row, skip);

    if (result.data.code === 200) {
      setAll(result.data.result);
      setUserCount(result.data.total_count);
      setIsLoading(false);
    } else if (result.data.code === 203) {
      toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }
  };
  useEffect(() => {
    getAll();
  }, [key, row, skip]);

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery);
    setSkip(0);
  };

  const handleTransactions = (data) => {
    // setOpenTransactionsModal(true);
    // setAllTransactions(data);
  };

  const handleEnvelopes = (data) => {
    setOpenEnvelop(true);
    setAllEnvelepes(data);
  };
  function convertEpochToSpecificTimezone(timeEpoch, offset) {
    var d = new Date(timeEpoch);
    var utc = d.getTime() + d.getTimezoneOffset() * 60000; //This converts to UTC 00:00
    var nd = new Date(utc + 3600000 * offset);
    return nd.toLocaleString();
  }
  const handleChange = (e) => {
    if (e.target.value === usersCount) {
      setSkip(0);
      setRow(usersCount);
    } else {
      setRow(e.target.value);
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <Toptag />
            <NavPaper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div></div>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {all.length > 0 ? (
                    <>
                      <Grid item>
                        <div
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "grey",
                          }}
                        >
                          Row Per Page{" "}
                        </div>
                      </Grid>
                      <Grid item>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 50 }}
                        >
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard-label"
                            value={row}
                            onChange={handleChange}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={usersCount}>All</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item>
                    <div className="searchBar">
                      <input
                        type="text"
                        placeholder="Search... "
                        onChange={searchHandle}
                        id="searchtext"
                        className="searchBarInput"
                      />
                      <Search className="searchIcon" />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 ? (
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "30px" }}
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {/* <Grid item>
                    <img
                      src="/images/no-data.png"
                      alt="error-image"
                      style={{ width: "50%", heigth: "500px" }}
                    />
                  </Grid> */}
                  <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop:"100px"
                  }}
                >
                  <div className="errorMessage">No Data Found!</div>
                </Grid>
                </Grid>
              ) : (
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell onClick={() => navigate("/transactions")}>
                          User
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell align="center">Transactions</TableCell>
                        <TableCell align="center">Envelopes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {all.map((element, index) => {
                        return (
                          <>
                            <TableRow key={index + 1}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {
                                  <img
                                    src={
                                      element?.user?.profile_pic &&
                                      element?.user?.profile_pic !== ""
                                        ? `${Img_url}${element?.user?.profile_pic}`
                                        : "/images/blank_pic.png"
                                    }
                                    alt="user"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50px",
                                    }}
                                    onError={(event) =>
                                      (event.target.src =
                                        "/images/blank_pic.png")
                                    }
                                  />
                                }
                              </TableCell>
                              <TableCell>{element?.user?.name}</TableCell>
                              <TableCell>{element?.user?.email}</TableCell>
                              <TableCell>{element?.amount}</TableCell>

                              <TableCell align="center">
                                <Grid
                                  container
                                  spacing={1}
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Tooltip title="view profile">
                                      <RemoveRedEye
                                        onClick={() => {
                                          navigate("/transactions", {
                                            state: element._id,
                                          });
                                        }}
                                        className="visibilityIc"
                                      />
                                    </Tooltip>
                                  </Grid>

                                  <Grid item></Grid>
                                </Grid>
                              </TableCell>
                              <TableCell align="center">
                                <Grid
                                  container
                                  spacing={1}
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Tooltip title="view profile">
                                      
                                        <RemoveRedEye
                                           onClick={() => {
                                            navigate("/envelopes", {
                                              state: element.userId,
                                            });
                                          }}
                                          
                                          className="visibilityIc"
                                        />
                                       
                                    </Tooltip>
                                  </Grid>

                                  <Grid item></Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
            </BackPaper>
            {all.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div>
                  <Pagination
                    count={Math.ceil(usersCount / row)}
                    page={Math.floor(skip / row) + 1}
                    variant="outlined"
                    onChange={skipPagination}
                    shape="rounded"
                  />
                </div>
              </div>
            ) : null}
          </Container>

          <>
            <Modal
              open={openTransactionsModal}
              onClose={() => setOpenTransactionsModal(false)}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box sx={style}>
                <h2 style={{ color: "black" }}>Transactions</h2>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                      <TableCell>Deposit/Widthraw</TableCell>
                      <TableCell>Amount</TableCell>

                      <TableCell align="right">Transaction Date</TableCell>
                    </TableHead>
                    <TableBody>
                      {allTransactions.map((row) => (
                        <TableRow key={row._id}>
                          <TableCell component="th" scope="row">
                            {!row.in ? (
                              <>
                                <NorthEastIcon style={{ color: "red" }} />{" "}
                                <p style={{ color: "red" }}>Widthraw</p>
                              </>
                            ) : (
                              <>
                                <SouthWest style={{ color: "green" }} />{" "}
                                <p style={{ color: "green" }}>Deposit</p>
                              </>
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span>
                              <p style={{ color: !row.in ? "red" : "green" }}>
                                {row.in ? "+" : "-"} ${row.amount}
                              </p>
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            {convertEpochToSpecificTimezone(row.created_at, +3)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
            <Modal
              open={openEnvelop}
              onClose={() => setOpenEnvelop(false)}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box sx={style}>
                <h2 id="modal-title">Envelops</h2>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Deposit</TableCell>
                        <TableCell align="right">Start Date</TableCell>
                        <TableCell align="right">Completion Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allEnvelopes.map((row) => (
                        <TableRow key={row.title}>
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell align="right">${row.amount}</TableCell>
                          <TableCell align="right">${row.deposit}</TableCell>
                          <TableCell align="right">
                            {convertEpochToSpecificTimezone(row.start_date, +3)}
                          </TableCell>
                          <TableCell align="right">
                            {convertEpochToSpecificTimezone(row.end_date, +3)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
          </>
        </>
      )}
    </>
  );
}
