import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

import {
  TipsAndUpdates,
  PostAdd,
  Gavel,
  PrivacyTip,
  ArchiveOutlined,
  PeopleAltOutlined,
  HomeOutlined,
  Quiz,
  MovieFilter,
  Article,
  Group,
  ContactMail,
  TimeToLeave,
  EngineeringOutlined,
  AccountBalanceWallet,
  Receipt,
  Drafts,
  ColorLensRounded,
} from "@mui/icons-material";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <NavLink to="/home" className="link">
              <li className="sidebarListItem">
                <HomeOutlined color="action" className="sidebarIcon " />
                Home
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">User Management</h3>
          <ul className="sidebarList">
            <NavLink to="/users" className="link">
              <li className="sidebarListItem">
                <Group color="action" className="sidebarIcon" />
                USERS
              </li>
            </NavLink>
            {/* <NavLink to="/wallets" className="link">
              <li className="sidebarListItem">
                <EngineeringOutlined color="action" className="sidebarIcon" />
                Wallets
              </li>
            </NavLink> */}
            {/* <NavLink to="/deleted-users" className="link">
              <li className="sidebarListItem">
                <ArchiveOutlined color="action" className="sidebarIcon" />
                DELETED ACCOUNTS
              </li>
            </NavLink> */}
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Wallet Management</h3>
          <ul className="sidebarList">
            <NavLink to="/wallets" className="link">
              <li className="sidebarListItem">
                <AccountBalanceWallet
                  color="action"
                  className="sidebarIcon"
                />
                Wallet
              </li>
            </NavLink>
            <NavLink to="/transactions" className="link">
              <li className="sidebarListItem">
                <Receipt color="action" className="sidebarIcon" />
                Transactions
              </li>
            </NavLink>
            <NavLink to="/envelopes" className="link">
              <li className="sidebarListItem">
                <Drafts color="action" className="sidebarIcon" />
                Envelopes
              </li>
            </NavLink>
          </ul>
        </div>


        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Color Management</h3>
          <ul className="sidebarList">
            <NavLink to="/colors" className="link">
              <li className="sidebarListItem">
                <ColorLensRounded
                  color="action"
                  className="sidebarIcon"
                />
                Color
              </li>
            </NavLink>
        
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Content</h3>
          <ul className="sidebarList">
            <NavLink to="/about" className="link">
              <li className="sidebarListItem">
                <PostAdd color="action" className="sidebarIcon" />
                About
              </li>
            </NavLink>
            <NavLink to="/terms-&-conditions" className="link">
              <li className="sidebarListItem">
                <Gavel color="action" className="sidebarIcon" />
                TERMS & CONDITIONS
              </li>
            </NavLink>
            <NavLink to="/privacy-policy" className="link">
              <li className="sidebarListItem">
                <PrivacyTip color="action" className="sidebarIcon" />
                PRIVACY POLICY
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}
