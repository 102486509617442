import {useEffect, useState } from 'react';
import './home.css'
import { useNavigate } from "react-router-dom";
import Card from '../../components/cards/Card';



export default function Home() {
  const navigate = useNavigate();
  

  useEffect(() => {
    let deferredPrompt;

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      deferredPrompt = event;
      // Automatically trigger the install prompt
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('PWA installed');
          // You can add additional logic here after installation
        } else {
          console.log('PWA installation declined');
          // Handle installation decline if needed
        }
        deferredPrompt = null;
      });
    });
    
    // Check if the app is already installed
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      console.log('PWA is already installed');
    } else {
      console.log('PWA is not installed');
      // Display a message to encourage installation (optional)
    }
    navigate('/home')
  }, [])
  
   
  
  return (
    <div className='home'>
       
        <Card/>
        
        </div>
  )
}
