import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import {
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableCell,
  TableHead,
  Select,
  TableRow,
  TableBody,
  Tooltip,
  Modal,
  Box,
  TableContainer,
  styled,
  Pagination,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import Toptag from "../../components/topTag/Toptag";
import { useLocation, useNavigate } from "react-router-dom";

import {
  RemoveRedEye,
  Search,
  SouthWest,
  DraftsOutlined,
  Delete,
} from "@mui/icons-material";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import APIFunctions from "../../ApiFunction";
import { toast } from "react-toastify";
import ColorPicker from "react-pick-color";
const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const BackPaper = styled(Paper)({
  height: "70vh",
  overflow: "auto",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  //   maxWidth: 600,

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Color() {
  const [all, setAll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [usersCount, setUserCount] = useState(0);
  const navigate = useNavigate();
  const [row, setRow] = useState(10);
  const [skip, setSkip] = useState(0);
  const location = useLocation();
  const [key, setKey] = useState(location.state || "");
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let color = `rgba(${inputValue.r},${inputValue.g},${inputValue.b},${inputValue.a})`;

    try {
      const add = await APIFunctions.addColor(color);

      if (add.status == 201) {
        toast.success(add?.data?.message);
        getAll();

        handleClose();
      }
    } catch (error) {}
  };

  const handleDelete = async (_id) => {
    try {
      const res = await APIFunctions.deleteColor(_id);
      console.log(res);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        getAll();
      } else {
        toast.error("Something went wrong!!");
      }
    } catch (error) {}
  };
  const getAll = async () => {
    const result = await APIFunctions.getColors(key, row, skip);

    if (result.data.code === 200) {
      setAll(result.data.result);
      setUserCount(result.data.total_count);
      console.log("result.data.total_count", result.data.result);
      setIsLoading(false);
    } else if (result.data.code === 203) {
      toast.info("Session Expired. Please login again!!");
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }
  };
  useEffect(() => {
    getAll();
  }, [row, key, skip]);
  function convertEpochToSpecificTimezone(timeEpoch, offset) {
    var d = new Date(timeEpoch);
    var utc = d.getTime() + d.getTimezoneOffset() * 60000; //This converts to UTC 00:00
    var nd = new Date(utc + 3600000 * offset);
    return nd.toLocaleString();
  }
  const handleChange = (e) => {
    if (e.target.value === usersCount) {
      setSkip(0);
      setRow(usersCount);
    } else {
      setRow(e.target.value);
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row);
  };
  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery);
    setSkip(0);
  };
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <Grid item>
            <Toptag />
          </Grid>

          <NavPaper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div></div>
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {all.length > 0 ? (
                  <>
                    <Grid item>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Row Per Page{" "}
                      </div>
                    </Grid>
                    <Grid item>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard-label"
                          value={row}
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={usersCount}>All</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : null}
                <Grid item>
                  {/* <div className="searchBar">
                    <input
                      type="text"
                      placeholder="Search... "
                      onChange={searchHandle}
                      id="searchtext"
                      className="searchBarInput"
                    />
                    <Search className="searchIcon" />
                  </div> */}
                </Grid>
                <Box
                  sx={{
                    margin: "10px",
                    paddingTop: "20px",
                    color: "white",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                  >
                    Add
                  </Button>
                </Box>
              </Grid>
            </div>
          </NavPaper>
          <BackPaper>
            {all.length === 0 ? (
              <Grid
                container
                spacing={2}
                style={{ marginTop: "30px" }}
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "100px",
                  }}
                >
                  <div className="errorMessage">No Data Found!</div>
                </Grid>
              </Grid>
            ) : (
              <>
                <Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                      <TableHead>
                        <TableCell>Sr. No.</TableCell>

                        <TableCell>Color</TableCell>
                        <TableCell>Color code</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableHead>
                      <TableBody>
                        {all.map((row, index) => (
                          <TableRow key={row._id}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  backgroundColor: `${row.color}`,
                                  borderRadius:"12%"
                                }}
                              ></div>
                              {/* {row?.color} */}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.color}
                            </TableCell>
                            <TableCell>
                              {convertEpochToSpecificTimezone(
                                row.created_at,
                                +3
                              )}
                            </TableCell>
                            <TableCell>
                              <TableCell align="center">
                                <Grid
                                  container
                                  spacing={1}
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Tooltip title="view profile">
                                      <Delete
                                        onClick={() => handleDelete(row._id)}
                                        className="visibilityIc"
                                      />
                                    </Tooltip>
                                  </Grid>

                                  <Grid item></Grid>
                                </Grid>
                              </TableCell>
                            </TableCell>
                           
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            )}
          </BackPaper>
          {all.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div>
                <Pagination
                  count={Math.ceil(usersCount / row)}
                  page={Math.floor(skip / row) + 1}
                  variant="outlined"
                  onChange={skipPagination}
                  shape="rounded"
                />
              </div>
            </div>
          ) : null}
        </Container>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography m={1} variant="h4" component="h2">
            Add Color
          </Typography>
          <form onSubmit={handleSubmit}>
            <ColorPicker
              color={inputValue}
              onChange={(color) => setInputValue(color.rgb)}
              theme={{
                background: 'white',
                inputBackground: 'white',
                borderColor: 'darkgrey',
                borderRadius: '8px',
                color: 'black',
                width: '700px'
                
              }}

            />
            <Box
              sx={{
                margin: "10px",
                paddingTop: "20px",
                
              }}
            >
              <Button   variant="contained" color="primary"  type="submit">
                Add
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
