import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
  return (
    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', height: '80vh'}}>
      <div>
      <CircularProgress className='loader' style={{color: 'red'}}/>
      </div>
    </div>
  )
}


