import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import "./profile.css";
import Toptag from "../../components/topTag/Toptag";
import {
  AddToPhotos,
  ConnectingAirportsOutlined,
  DeleteOutlined,
  PhotoOutlined,
  Publish,
  TwoKPlus,
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../components/loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { Grid, Paper } from "@mui/material";
import APIFunctions from "../../ApiFunction";
import { BASE_URL, Img_url } from "../../Api";
import Topbar from "../../components/topbar/Topbar";
import { setAdmin } from "../../slice/adminslice";
import { useDispatch } from "react-redux";

const NavPaper = styled(Paper)({
  padding: "20px",
  borderRadius: "0",
  backgroundColor: "white",
  marginBottom: "5px",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const BackPaper = styled(Paper)({
  padding: "10px",
  height: "440px",
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});
export default function Profile() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [detail, setDetail] = useState({ name: "", email: "", profile_pic: "" });
  const [putObjurl, setPutObjurl] = useState({ url: '', key: '' });
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [edit, setEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [err, setError] = useState(false);
  const dispatch = useDispatch();




  const editFunction = () => {
    setEdit(true);
  };

  const inputEvent = (e) => {
    setDetail({
      ...detail,
      [e.target.name]: e.target.value
    });
  };
  // const imageChange = (e) => {
  //   console.log('one')
  //   console.log(e.target.files[0].type,'hiting');
  //   getPutObjUrl(e.target.files[0].type); // getting the url here
  //   setImage2(e.target.files[0]);
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };
  const imageChange = (e) => {
    setImage2(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))
  };


  // const getData = async () => {
  //   const result = await APIFunctions.getAdminProfileDetail();
  //   if (result.data.code === 200) {
  //     setIsLoading(false);
  //     setDetail(result.data.result);
  //     setImage(result.data.result.urlpic)
  //     dispatch(setAdmin(result.data.result.urlpic))
  //     localStorage.setItem("Admin_image", result.data.result.urlpic);
  //   } else if (result.data.code === 203) {
  //     window.location.reload()
  //     localStorage.clear();
  //     navigate("/");
  //     toast.info("Session Expired Please login again!!");
  //   }
  // };

  const getData = async () => {
    const result = await APIFunctions.getAdminProfileDetail();
    if (result.data.code === 200) {
      setIsLoading(false);
      setDetail(result.data.result);
      dispatch(setAdmin(result.data.result.profile_pic))
      localStorage.setItem("Admin_image", result.data.result.profile_pic);
      setImage(`${Img_url}${result.data.result.profile_pic}`)
    } else if (result.data.code === 203) {
      window.location.reload();
      localStorage.clear();
      navigate("/");
      toast.info("Session Expired Please login again!!");
    }
  };
  // const updateAdminProfile = async () => {
  //   try {
  //     if (detail.name === "") {
  //       setError(true)
  //     } else {
  //       // await axios.put(presignedURL, formData); //image uploading
  //       if(image2 !== ""){
  //         // const formData = new FormData();
  //         // formData.append('file', image2);
  //         // console.log(image2, "image2 ")
  //         const presignedURL = putObjurl.url;
  //         await axios.put(presignedURL, image2, {
  //           headers: {
  //             'Content-Type': image2.type // Set the content type based on the file's type
  //           }
  //         });
  //       }
  //       let obj = {name: detail.name, email: detail.email, profile_pic: putObjurl.key!== "" ? putObjurl.key : detail.profile_pic}
  //       const result = await APIFunctions.UpdateAdminProfileDetail(obj);
  //       setDisable(true)
  //       if (result.data.code === 200) {
  //         setDisable(false)
  //         setEdit(false)
  //         getData();
  //         setError(false)
  //         setImage("")
  //         toast.success("Updated successfully!", {
  //           position: "top-center"
  //         });
  //         dispatch(setAdmin(localStorage.setItem("Admin_image")))
  //       } else if (result.data.code === 201) {
  //         toast.error("error are coming for fetching images");
  //       } else if (result.data.code === 203) {
  //         window.location.reload()
  //         localStorage.clear();
  //         navigate("/");
  //         toast.info("Session Expired Please login again!!");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.message)
  //   }
  // };

  const updateAdminProfile = async () => {
    try {
      if(detail.name===""){
        setError(true)
      }else{
        const formData = new FormData();
        formData.append("name", detail.name);
        formData.append("email", detail.email);
        formData.append("profile_pic", image2 ? image2:detail.profile_pic);
        setDisable(true)
        const result = await APIFunctions.UpdateAdminProfileDetail(formData);
        if (result.data.code === 200) {
          setDisable(false)
          setEdit(false)
          getData();
          setError(false)
          setImage("")
          toast.success("Updated successfully!",{
            position:"top-center"
          });
          dispatch(setAdmin(localStorage.setItem("Admin_image")))
        } else if (result.data.code === 201) {
          toast.error("error are coming for fetching images");
        } else if (result.data.code === 203) {
          window.location.reload()
          localStorage.clear();
          navigate("/");
          toast.info("Session Expired Please login again!!");
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  };




  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <Toptag />
        
          <NavPaper>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center" >
              <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }} >
                Profile&nbsp;&nbsp;<i className="fa-solid fa-key"></i>
              </Grid>
              <Grid item>
                {!edit ? (
                  <button className="newBtn" onClick={editFunction}>Edit</button>
                ) : null}
              </Grid>
            </Grid>
          </NavPaper>
          <BackPaper>
            <Grid container spacing={4}>
              <Grid xs={12} sm={12} md={6} lg={3} item>
                <Grid container spacing={2} direction="column" justifyContent="space-evenly" alignItems="center" >
                  <Grid item>
                    <img src={image !== "" ? image : '/images/blank_pic.png'} alt="selected_image" className="imgCont1" />
                  </Grid>
                  <Grid item>
                    {edit ?
                      <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                          <label htmlFor='imagechange'>
                            <div className='purchaseChip' style={{ background: "none", width: "225px", textAlign: "center", fontSize: "13px", color: "#465290" }}>
                              <div><PhotoOutlined /></div>
                              <div>New Profile Picture</div>
                            </div>
                          </label>
                          <input type='file' id="imagechange" onChange={(e) => imageChange(e)} style={{ display: 'none' }} accept="image/*" />
                        </Grid>
                        {/* {detail.profile_pic==='blank_pic.png' || image2==="blank_pic.png"?null:
                        <Grid item>
                          <div className='purchaseChip' onClick={removeCurrentimage} style={{ background: "none", width: "225px", textAlign: "center", fontSize: "13px", color: "red" }}>
                            <div><DeleteOutlined /></div>
                            <div>Remove Current Picture</div>
                          </div>
                        </Grid>} */}
                      </Grid>
                      : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={9} item>
                <Grid container spacing={1} direction="column" justifyContent="space-evenly" alignItems="flex-start"  >
                  <Grid item>
                    <label className="myLabel">Name</label>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" name="name" disabled={!edit} value={detail.name} id="name" onChange={inputEvent} className="BarInput" />
                    {err && detail.name === "" ? <div className="errText">Name should not be empty!</div> : null}
                  </Grid>
                  <Grid item>
                    <label className="myLabel">Email</label>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" name="email" disabled value={detail.email} id="email" onChange={inputEvent} className="BarInput" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" >
              <Grid item>
                {edit ? (
                  <div className="SubmitBtnBox">
                    <Tooltip title="Click to Update profile">
                      <button className="newBtn" onClick={updateAdminProfile} disabled={disable}>Save</button>
                    </Tooltip>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </BackPaper>
        </div>
      )}
    </>
  );
}
