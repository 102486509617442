import { createSlice } from '@reduxjs/toolkit';



export const adminSlice = createSlice({
    name: 'adminDetails',
    initialState: { Admin_image: ''},
    reducers: {
        setAdmin: (state, action) => {
            state.Admin_image = action.payload;
        },
    },
})



export const { setAdmin } = adminSlice.actions

export default adminSlice.reducer