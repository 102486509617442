import { createSlice } from '@reduxjs/toolkit'
import { fetchUsersCount } from '../action/count';


const countSlice = createSlice({
  name: 'count',
  initialState: { usersCount: 0, wallet: 0, transaction: 0,envelopes:0,  isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersCount = action.payload.user;
        state.wallet = action.payload.wallet;
        state.transaction = action.payload.transaction;
        state.envelopes = action.payload.envelopes;

      })
      .addCase(fetchUsersCount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
})



export default countSlice.reducer



