import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import ChangePass from './pages/profile/ChangePass';
import UserDetail from './pages/user/UserDetail';
import User from './pages/user/User';
import Terms from './pages/content/Terms';
import Privacy from './pages/content/Privacy';

import About from './pages/content/Desclaimer';
import Wallets from './pages/Wallets/Wallets';
import Transactions from './pages/Transactions/Transactions';
import Envelopes from './pages/Envelopes/Envelopes';
import Color from './pages/color/Color';
import AddUser from './pages/user/AddUser';
import EditUser from './pages/user/EditUser';





export default  [
    // auth routes
    { path: "/" ,  Component: <Home/> },
    { path: "/profile" ,    Component: <Profile/> },
    { path: "/change-password" ,    Component: <ChangePass/> },
    { path: "/users" ,    Component: <User/> },
    { path: "/home/users" ,  Component: <User/> },
    { path: "/users/details" ,   Component: <UserDetail/> },
    { path: "/users/add" ,   Component: <AddUser/> },
    { path: "/users/edit" ,   Component: <EditUser/> },


// terms & about routes
    { path: "/about" ,   Component: <About/> },
    { path: "/terms-&-conditions" ,   Component: <Terms/> },
    { path: "/privacy-policy" ,   Component: <Privacy/> },
    
    // wallet routes
    { path: "/wallets" ,  Component: <Wallets/> },
    { path: "/transactions" ,  Component: <Transactions/> },
    { path: "/envelopes" ,  Component: <Envelopes/> },
// color routes
    { path: "/colors" ,  Component: <Color/> },
 




    { path: "*" ,  Component: <Home/> },

]
