import { configureStore } from "@reduxjs/toolkit";
import countReducer from "../src/slice/count"
import adminReducer from "../src/slice/adminslice"

export const store = configureStore({
  reducer: {
    count: countReducer,
    admin: adminReducer
  },
});
