import API from "./Api";
import axios from "axios";

const headerOptions = {
  headers: {
    token: localStorage.getItem("admintoken"),
  },
};

const APIFunctions = {
  login: (data) => {
    return axios.post(`${API.LOGIN}`, data);
  },
  auth: () => {
    return axios.get(`${API.AUTH}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  logout: () => {
    return axios.post(
      `${API.LOGOUT}`,
      {},
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  putObjectUrl: (contentType) => {
    return axios.get(API.PUTOBJURL, {
      params: {
        contentType: contentType,
      },
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  UpdateAdminProfileDetail: (data) => {
    return axios.post(`${API.UPDATE_ADMIN_PROFILE}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  forgotPassword: (data) => {
    return axios.post(`${API.forgotPassword}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  changeAdminPassword: (data) => {
    return axios.post(`${API.CHANGE_PASSWORD}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  addEditUser: (data) => {
    return axios.post(`${API.ADD_EDIT_USER}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  deleteUser: (id) => {
    return axios.delete(`${API.DELETE_USER}/${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getWallets: (key, row, skip) => {
    return axios.get(
      `${API.GET_WALLETS}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  getColors: (key, row, skip) => {
    return axios.get(
      `${API.GET_COLORS}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  addColor: (color) => {
    return axios.post(
      `${API.ADD_COLOR}`,
      { color },
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  deleteColor: (_id) => {
    return axios.delete(
      `${API.DELETE_COLOR}/${_id}`,     
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  getAllEnvelopes: (key, row, skip) => {
    return axios.get(
      `${API.GET_ALL_ENVELOPES}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  getAllTransactions: (key, row, skip) => {
    return axios.get(
      `${API.GET_ALL_TRANSACTIONS}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  getCounts: () => {
    return axios.get(`${API.COUNT}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAllNormalUser: (key, row, skip) => {
    return axios.get(
      `${API.GET_ALL_NORMAL_USER}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  getAllTechnicians: (key, row, skip) => {
    return axios.get(
      `${API.GET_ALL_TECHNICIAN}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  getAllVehicles: (key, row, skip) => {
    return axios.get(
      `${API.GET_ALL_VEHICLES}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },

  getSinleUserDetail: (id) => {
    return axios.get(`${API.GET_USER_DETAILS}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  GET_VEHICLE_DETAILS: (id) => {
    return axios.get(`${API.GET_VEHICLE_DETAILS}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  get_vehicle_dropDownListing: (id) => {
    return axios.get(`${API.get_vehicle_dropDownListing}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  GET_VIDEO_DETAILS: (id) => {
    return axios.get(`${API.GET_VIDEO_DETAILS}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  GET_VIDEO_DETAIL_EDITSCREEN: (id) => {
    return axios.get(`${API.GET_VIDEO_DETAIL_EDITSCREEN}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getTriviaQueDetail: (id) => {
    return axios.get(`${API.GET_TRIVIAQUE_DETAIL}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getVideoDetail: (id) => {
    return axios.get(`${API.GET_VIDEO_DETAIL}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  get_service_guide_detail: (id) => {
    return axios.get(`${API.GET_SERVICEGUIDE_DETAIL}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  get_tip_detail: (id) => {
    return axios.get(`${API.GET_TIP_DETAIL}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  updateUserDetails: (data) => {
    return axios.post(`${API.UPDATE_USER_DETAILS}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAdminProfileDetail: () => {
    return axios.get(`${API.GET_ADMIN_PROFILE}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },

  getAllDeletedUsers: (key, row, skip) => {
    return axios.get(
      `${API.GET_ALL_DELETED_USERS}?search=${key}&limit=${row}&skip=${skip}`,
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },

  addContactUsOptions: (data) => {
    return axios.post(`${API.ADD_CONTACT_US_OPTIONS}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  addtriviaquestions: (data) => {
    return axios.post(`${API.ADD_TRIVIA_QUE}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAllcontactOptions: () => {
    return axios.get(`${API.GET_ALL_CONTACT_OPTIONS}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  editontactUsOptions: (data) => {
    return axios.post(`${API.EDIT_CONTACT_OPTIONS}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAllTriviaQue: () => {
    return axios.get(`${API.GET_ALL_TRIVIA_QUE}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAllServiceGuidePages: () => {
    return axios.get(`${API.GET_ALL_SERVICEGUIDE_PAGE}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAllVideos: () => {
    return axios.get(`${API.GET_ALL_VIDEOS}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },

  GET_ALL_TIPS: () => {
    return axios.get(`${API.GET_ALL_TIPS}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },

  getAllContactMessages: () => {
    return axios.get(`${API.GET_ALL_CONTACT_MESSAGES}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },

  getSingleContactMessages: (id) => {
    return axios.get(`${API.GET_CONTACT_MESSAGE_DETAIL}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getDefaulttext: () => {
    return axios.get(`${API.GET_DEFAULT_TEXT}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  updateDefaulttext: (data) => {
    return axios.post(`${API.UPDATE_DEFAULT_TEXT}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },

  updateBlockStatus: (id, data) => {
    return axios.post(`${API.UPDATE_BLOCK_UNBLOCK_USER}?id=${id}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },

  updateEmailStatus: (id) => {
    return axios.post(
      `${API.UPDATE_EMAIL_STATUS_USER}?id=${id}`,
      {},
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  updateContactStatus: (id) => {
    return axios.post(
      `${API.UPDATE_CONTACT_STATUS_USER}?id=${id}`,
      {},
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },

  ArchivedUserStatus: (id) => {
    return axios.post(
      `${API.UNARCHIVED_USER}?id=${id}`,
      {},
      {
        headers: {
          token: localStorage.getItem("admintoken"),
        },
      }
    );
  },
  deleteUserPermanent: (id) => {
    return axios.delete(`${API.DELETE_USER}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  deleteTriviaQue: (id) => {
    return axios.delete(`${API.DELETE_TRIVIA_QUE}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  delete_service_guide: (id) => {
    return axios.delete(`${API.DELETE_SERVICEGUIDE}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  DELETE_VIDEO: (id) => {
    return axios.delete(`${API.DELETE_VIDEO}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  DELETE_TIP: (id) => {
    return axios.delete(`${API.DELETE_TIP}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getContentByType: (type) => {
    return axios.get(`${API.GET_CONTNET_BY_TYPE}?type=${type}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  updateContentByType: (data) => {
    return axios.post(`${API.UPDATE_CONTNET_BY_TYPE}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  addserviceguide: (data) => {
    return axios.post(`${API.ADD_SERVICE_GUIDE}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  addVideos: (data) => {
    return axios.post(`${API.ADD_VIDEOS}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  UPDATE_VIDEOS: (data) => {
    return axios.post(`${API.UPDATE_VIDEOS}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  updateServiceGuide: (data) => {
    return axios.post(`${API.UPDATE_SERVICE_GUIDE}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  addTips: (data) => {
    return axios.post(`${API.ADD_TIPS}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  updateTips: (data) => {
    return axios.post(`${API.UPDATE_TIP}`, data, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAllFlashCard: (row, skip) => {
    return axios.get(`${API.GET_ALL_FLASHCARD}?limit=${row}&skip=${skip}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getFlashCardDetails: (id) => {
    return axios.get(`${API.GET_FLASHCARD_DETAILS}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getAllTags: (row, skip) => {
    return axios.get(`${API.GET_ALL_TAGS}?limit=${row}&skip=${skip}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
  getTagsDetails: (id) => {
    return axios.get(`${API.GET_TAGS_DETAILS}?id=${id}`, {
      headers: {
        token: localStorage.getItem("admintoken"),
      },
    });
  },
};

export default APIFunctions;
