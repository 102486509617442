import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Paper,
  Typography
} from "@mui/material";
import "./adduser.css";
import APIFunctions from "../../ApiFunction";
import { useLocation, useNavigate } from "react-router-dom";

const EditUserForm = () => {
    const navigate= useNavigate()
    const location = useLocation()
    console.log('location', location)
  const [formValues, setFormValues] = useState({
    name:location.state.name,
    email: location.state.email,  
    contact:location.state.contact,
    _id:location.state._id
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
        let editUser = await APIFunctions.addEditUser(formValues)
        console.log(editUser)
        navigate('/users')

    } catch (error) {
        
    }
  
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="root">
        <Typography component="h1" variant="h5">
          Edit User
        </Typography>
        <Paper className="paper">
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={formValues.name}
              onChange={handleChange}
              className="textField"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formValues.email}
              onChange={handleChange}
              className="textField"
            />
           
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="contact"
              label="Contact"
              name="contact"
              autoComplete="contact"
              value={formValues.contact}
              onChange={handleChange}
              className="textField"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
            >
              Edit User
            </Button>
          </form>
        </Paper>
      </div>
    </Container>
  );
};

export default EditUserForm;
