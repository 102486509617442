import "./login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIFunction from "../../ApiFunction";
import { setAdmin } from "../../slice/adminslice";
import { useDispatch } from "react-redux";

export default function Login({onLogin}) {
  const navigate = useNavigate();
  const [style, setStyle] = useState("container1 left-panel-active");
  const dispatch = useDispatch()
  const [disable,setDisable] = useState(false)
  const [obj, setObj] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState(false);

  const changeStyle1 = () => {
    setStyle("container1 right-panel-active");
  };
  const changeStyle2 = () => {
    setStyle("container1 left-panel-active");
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const inputEvent = (e) => {
    const { id, value } = e.target;
    setObj((prevalue) => {
      return {
        ...prevalue,
        [id]: value,
      };
    });
  };

  const submitForm = async (event) => { 
    event.preventDefault();
    try {
      setDisable(true)
      const result = await APIFunction.login(obj);
      console.log(result, "result of lis")
      if (result.data.code === 200) {
        setDisable(false)
        localStorage.setItem("admintoken", result.data.result.token);
        toast.success("LoggedIn successfully");
        localStorage.setItem("Admin_image", result.data.result.profile_pic);
        dispatch(setAdmin(result.data.result.profile_pic));
        navigate("/home");
        onLogin();
        // window.location.reload();
        
      } else if (result.data.code === 201) {
        toast.info("Invalid credentails!");
        setDisable(false)
      }
    } catch (err) {
      console.log(err.message);
    }
  };



  const submitFormForgotPass = async (event) => {
    event.preventDefault();
    try {
      const result = await APIFunction.forgotPassword(obj);
      if (result.data.code === 200) {
        setMessage(true)
      } else if (result.data.code === 201) {
        toast.info("Account does not exist!");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <ToastContainer  autoClose={1000}/>
      {/* <div className="fill_center" style={{backgroundImage: `url('/images/background.jpg')`}}> */}
      <div className="fill_center" style={{background: "#50BC9E"}}>
        <div className={style} id="container">
          <div className="form-container sign-up-container">
            <form onSubmit={submitFormForgotPass}>
              {message === true ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="message">New password generated successfully!</div>
                    <div className="paraMessage">
                      A temporary generated password is sent to your email. Please use that to login.
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src="/images/Spend app icon.jpg"
                    alt=""
                    className="loginLogo"
                  />
                  <h1 className="heading2">Recover Account</h1>
                  <input
                    type="email"
                    id="recover_email"
                    onKeyDown={handleKeyDown}
                    onChange={inputEvent}
                    required={true}
                    placeholder="Email"
                  />
                  <button className="newBtn" style={{ marginTop: "17px" }}>
                    Submit
                  </button>
                </>
              )}
            </form>
          </div>

          <div className="form-container sign-in-container">
            <form onSubmit={submitForm}>
              <img src="/images/Spend app icon.jpg" alt="" className="loginLogo" />
              <h1 className="heading1">SIGN IN</h1>
              <input
                type="email"
                id="email"
                onKeyDown={handleKeyDown}
                onChange={inputEvent}
                required={true}
                placeholder="Email"
              />
              <input
                type="password"
                id="password"
                onKeyDown={handleKeyDown}
                onChange={inputEvent}
                required={true}
                placeholder="Password"
              />
              <button className="newBtn" style={{ marginTop: "17px" }} disabled={disable}>
                Sign In
              </button>
            </form>
          </div>

          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Hello, Admin!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button className="ghost" id="signIn" onClick={changeStyle2}>
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Welcome Back!</h1>
                <p>To recover your account password, Click below!</p>
                <button className="ghost" id="signUp" onClick={changeStyle1}>
                  Forgot Password?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
